$(() => {
    // Copy main nav to offcanvas
    const fullscreenMenuToggler = $('#fullscreenMenuToggler');
    const fullscreenMenu = $('#fullscreenMenu');

    if (fullscreenMenuToggler.length && fullscreenMenu.length) {

        fullscreenMenu.get(0).addEventListener('show.bs.offcanvas', event => {
            fullscreenMenuToggler.addClass('is-active');
        })

        fullscreenMenu.get(0).addEventListener('hide.bs.offcanvas', event => {
            fullscreenMenuToggler.removeClass('is-active');
        })
    }
});

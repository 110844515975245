import './bedrock'

import './is-touch-device'
import './prevent-content-jump'

import './components/header/header'
import './components/header/header-scroll'
import './components/banner'
import './components/menu/default-menu'
import './components/menu/fullscreen-menu'
import './components/blocks/switch-language'

import './animations';
